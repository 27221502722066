let labelShortcutDetails = () => {
    return {
        product_name: {
            "display_label_ar": "اسم المنتج",
            "display_label_en": "Product Name"
        },
        quantity: {
            "display_label_ar": "الكمية",
            "display_label_en": "Quantity"
        },
        unit: {
            "display_label_ar": "الوحدة",
            "display_label_en": "Unit"
        },

        discount_calc: {
            "display_label_ar": "خصومات",
            "display_label_en": "Discounts"
        },
        subtotal_before_discount: {
            "display_label_ar": "المجموع قبل الخصم",
            "display_label_en": "Total Before Discount"
        },
        subtotal_after_discount: {
            "display_label_ar": "المجموع بعد الخصم",
            "display_label_en": "Total After Discount"
        },
        tax_applied: {
            "display_label_ar": "نــسـبـة الضريبة",
            "display_label_en": "VAT"
        },

        tax_val: {
            "display_label_ar": "قــيــمـة الضريبة",
            "display_label_en": "VAT Value"
        },
        unit_price: {
            "display_label_ar": "ســعر الوحدة",
            "display_label_en": "U. Rate"
        },
        total_before_tax: {
            "display_label_ar": "المجموع قبل VAT",
            "display_label_en": "Total Before VAT"
        },
        total_after_tax: {
            "display_label_ar": "المجموع بعد VAT",
            "display_label_en": "Total After VAT"
        },
        description: {
            "display_label_ar": "الوصف",
            "display_label_en": "Description"
        },
    }
}
let getLabelShortcutFromInnerData = (_slug, lang) => {
    let _labels = labelShortcutDetails();
    return _labels[_slug] ? _labels[_slug]['display_label_'+lang] : '';

}
let getLabelShortcutFromSettingsPrint = ( _slug, lang, _labels_list = [], isUseLabelShortcut = false) => {
    let _label = getLabelShortcutFromInnerData(_slug, lang);
    if(_labels_list.length > 0 && isUseLabelShortcut){
        let _label_obj = _labels_list.find((_row)=> _row.slug == _slug && _row.is_active == true);
        if (_label_obj){
            _label = _label_obj['display_label_'+lang];
        }
    }
    return _label;
}
export default {getLabelShortcutFromSettingsPrint};